import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BackButtonDirective } from 'src/app/directives/back-button.directive';
import { AppMaterialModule } from 'src/app/modules/app-material.module';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, BackButtonDirective],
})
export class BackButtonComponent {
  constructor() {}
}
